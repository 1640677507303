<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'actors' })" />
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <p class="h2 text-secondary">Modifier un acteur</p>
        <b-button variant="secondary">Valider</b-button>
        <hr class="w-100" />
      </div>
      <div class="row">
        <!-- First Col -->
        <div class="col-12 col-md-4">
          <b-form-group label-cols="12" label="Nom" label-class="font-weight-bold">
            <b-form-input type="text"></b-form-input>
          </b-form-group>
          <b-form-group label-cols="12" label="Email" label-class="font-weight-bold">
            <b-form-input type="text"></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols="12"
            label="Organisation"
            label-class="font-weight-bold"
          >
            <b-form-input type="text"></b-form-input>
          </b-form-group>

          <b-form-group label-cols="12" label="Fonction" label-class="font-weight-bold">
            <b-form-select
              :options="['Fonction 1', 'Fonction 2', 'Fonction 3']"
            ></b-form-select>
          </b-form-group>
        </div>

        <!-- Second Col -->
        <div class="col-12 col-md-4">
          <b-form-group label-cols="12" label="Prénom" label-class="font-weight-bold">
            <b-form-select
              :options="['Damon Harvey', 'Lionel Runte', 'Scott Langosh']"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols="12" label="Type" label-class="font-weight-bold">
            <b-form-input type="text"></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label="Niveau hiérarchique"
            label-class="font-weight-bold"
          >
            <b-form-select :options="['Directeur', 'Manager', 'Employé']"></b-form-select>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label="Statut de compte"
            label-class="font-weight-bold"
          >
            <b-form-checkbox switch size="lg" variant="success"></b-form-checkbox>
          </b-form-group>
        </div>

        <!-- Third Col -->
        <div class="col-12 col-md-4">
          <b-form-group label-cols="12" label="Photo" label-class="font-weight-bold">
            <drop-zone
              :options="{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 'My-Awesome-Header': 'header value' },
              }"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
          <p class="h3 text-secondary">Equipes affectées</p>
          <b-button variant="secondary" class="align-btn-table" @click="addNewTeamInputs"
            ><i class="mdi mdi-plus"></i> Affecter équipe</b-button
          >
          <hr class="w-100" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            responsive
            :items="affectedTeams.tableItems"
            :fields="affectedTeams.tableFields"
          >
            <template #cell(team_label)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{ data.value }}</span>
              <b-form-select v-else :options="[]"></b-form-select>
            </template>
            <template #cell(responsable)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{ data.value }}</span>
              <b-form-select v-else :options="[]"></b-form-select>
            </template>
            <template #cell(organisation_attachment)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{ data.value }}</span>
              <b-form-input
                v-else
                placeholder="Organisation de rattachement"
              ></b-form-input>
            </template>
            <template #cell(collaborators_number)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{ data.value }}</span>
              <b-form-input
                v-else
                type="number"
                placeholder="Nombre de collaborateurs"
              ></b-form-input>
            </template>
            <template #cell(actions)="data">
              <table-actions
                :actions="data.value"
                :editMode="data.item.hasOwnProperty('editMode')"
                @cancelItem="removeTeamInputs(data.index)"
              ></table-actions>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import TableActions from "@/components/custom/TableActions.vue";
import DropZone from "../../../components/custom/DropZone.vue";

export default {
  components: {
    ModalActions,
    TableActions,
    DropZone,
  },
  data: () => ({
    affectedTeams: {
      tableItems: [
        {
          team_label: "Lorem Ipsum",
          responsable: "Leslie Bechtelar",
          organisation_attachment: "Cadrage",
          collaborators_number: "4",
          actions: ["edit"],
        },
        {
          team_label: "Guillermo Nienow",
          responsable: "Ms. Melissa Fay",
          organisation_attachment: "Cadrage",
          collaborators_number: "6",
          actions: ["edit"],
        },
      ],
      tableFields: [
        { key: "team_label", label: "Libellé équipe", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        { key: "actions", label: "" },
      ],
    },
  }),
  methods: {
    addNewTeamInputs() {
      this.affectedTeams.tableItems.push({ editMode: true });
    },
    removeTeamInputs(itemIndex) {
      this.affectedTeams.tableItems = this.affectedTeams.tableItems.filter(
        (team, index) => index !== itemIndex
      );
    },
  },
};
</script>

<style></style>
